'use strict';

angular.module('enervexSalesappApp').controller('SpecificationsCtrl', function($scope, $stateParams, SpecificationsService, Account, Job, User, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	$scope.job = Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	});
	getSpecifications();
	function getSpecifications() {
		SpecificationsService.fetchSpecifications().$promise.then(function(specs){
			$scope.specifications = specs;
		});
	}
	$scope.removeSpecification = function(spec){
		SpecificationsService.deleteSpecification(spec).$promise.then(function(res){
			getSpecifications();
		});
	}
});
